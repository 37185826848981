import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import { Tabs, Tab, TabPanel, TabList, TabProvider } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import {
  ArrowRightICon,
  MobNextArrowIcon,
} from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import Cards from "../../components/chat-channel/Cards";
import useInterval from "../../react/useInterval";
import { useSwipeable, Swipeable } from "react-swipeable";
import { useMedia } from "use-media";
import { OnboardingSlider } from "../conversational-ai-platform";
import { OnboardingMobile } from "../conversational-ai-platform";
import { Features } from "../conversational-ai-platform";
import { Link } from "gatsby";
import { VideoElement2 } from "../../components/video";
import { AppsIntegrations } from "../conversational-ai-platform";
import { GoToPopup } from "../homepage";
import { redirectToWithUtm } from "../../components/Header";

const img = require("../../assets/img/feature/home/iso_sec_img.png");
const img_mob = require("../../assets/img/feature/home/iso_sec_img_mob.png");
const customerBanner = require("../../assets/img/feature/home/header_customer_banner.svg");
const bannerImageMobile = require("../../assets/img/feature/home/header_customer_banner_mobile.svg");

const AllFeatureImg1 = require("../../assets/img/chatbot/it_chatbot/it_support_features_1.png");
const AllFeatureImg2 = require("../../assets/img/chatbot/it_chatbot/it_bot_features_1.png");
const AllFeatureImg3 = require("../../assets/img/chatbot/it_chatbot/it_bot_features_3.png");
const AllFeatureImg4 = require("../../assets/img/chatbot/it_chatbot/it_support_onboarding_5.png");
const AllFeatureImg5 = require("../../assets/img/chatbot/it_chatbot/it_bot_features_5.png");

const FeatureSlack = require("../../assets/images/features_images/features_slack.png");
const FeatureTeams = require("../../assets/images/features_images/features_teams.png");
const FeatureChat = require("../../assets/images/features_images/features_chat.png");

const OnboardSlide = require("../../assets/images/onboarding/onboarding_slider_1.png");
const OnboardSlide1 = require("../../assets/images/onboarding/onboarding_slider_2.png");
const OnboardSlide2 = require("../../assets/images/usecases/v_agent/virtual_onboarding_3.png");
const OnboardSlide3 = require("../../assets/images/onboarding/onboarding_slider_4.png");
const OnboardSlide4 = require("../../assets/img/chatbot/it_chatbot/it_support_onboarding_5.png");
const OnboardArrow = require("../../assets/images/onboarding/onboarding_arrow.svg");

const unlock_account = require("../../assets/img/chatbot/it_chatbot/it_support_1.png");
const password_resets = require("../../assets/img/chatbot/it_chatbot/it_support_2.png");
const user_provisioning = require("../../assets/img/chatbot/it_chatbot/it_support_3.png");
const access_management = require("../../assets/img/chatbot/it_chatbot/it_support_4.png");
const asset_requests = require("../../assets/img/chatbot/it_chatbot/it_support_5.png");
const onboarding = require("../../assets/img/chatbot/it_chatbot/it_support_6.png");
const offboarding = require("../../assets/img/chatbot/it_chatbot/it_support_7.png");

const mobile = require("../../assets/images/landing_page/customers_mob.png");

const topImage = require("../../assets/img/chatbot/it_chatbot/it_support_header.png");
const VidImg = require("../../assets/img/chatbot/it_chatbot/vid_img_it_support.png");

const section_two = require("../../assets/img/chatbot/it_chatbot/it_chatbot_sec_1.png");
const section_four = require("../../assets/img/chatbot/it_chatbot/it_chatbot_sec_3.png");
const section_five = require("../../assets/img/chatbot/it_chatbot/it_chatbot_sec_4.png");

const noCoding = require("../../assets/images/landing_page/bottom_icon_1.png");
const supportAutomation = require("../../assets/images/landing_page/bottom_icon_2.png");
const boostIT = require("../../assets/images/landing_page/bottom_icon_3.png");

const bamboohr = require("../../assets/images/icons-web/bamboo.svg");
const orangehrm = require("../../assets/images/icons-web/orangehrm.svg");
const jira = require("../../assets/images/icons-web/jira.svg");
const solarwinds = require("../../assets/images/icons-web/solarwinds.svg");
const service_desk = require("../../assets/images/icons-web/service_desk.svg");
const jumpcloud = require("../../assets/images/icons-web/jumpcloud.svg");
const ad = require("../../assets/images/icons-web/zure.svg");
const office365 = require("../../assets/images/icons-web/ms_office.svg");
const okta = require("../../assets/images/icons-web/okta.svg");
const onelogin = require("../../assets/images/icons-web/onelogin.svg");
const bamboo = require("../../assets/images/icons-web/bamboo.svg");
const zoho_people = require("../../assets/images/icons-web/zoho_people_1.svg");
const gusto = require("../../assets/images/icons-web/gusto.svg");
const sap = require("../../assets/images/icons-web/sap_successfactors.svg");
const intune = require("../../assets/images/icons-web/ms_intune.svg");
const mobileiron = require("../../assets/images/icons-web/mobile_iron.svg");
const hexnode = require("../../assets/images/icons-web/hexnode.svg");
const citrix = require("../../assets/images/icons-web/citrix.svg");

const slides = [
  {
    image: unlock_account,
    title: "Unlock Account",
    tabFor: "vertical-tab-one",
  },
  {
    image: password_resets,
    title: "Password Reset",
    tabFor: "vertical-tab-two",
  },
  {
    image: user_provisioning,
    title: "User Provisioning",
    tabFor: "vertical-tab-three",
  },
  {
    image: access_management,
    title: "Access Management",
    tabFor: "vertical-tab-four",
  },
  {
    image: asset_requests,
    title: "Asset Requests",
    tabFor: "vertical-tab-five",
  },
  {
    image: onboarding,
    title: "Onboarding",
    tabFor: "vertical-tab-six",
  },
  {
    image: offboarding,
    title: "Offboarding",
    tabFor: "vertical-tab-seven",
  },
];

const buttonMenu = [
  {
    tabFor1: "vertical-tab-one",
    tabName: "unlock account",
  },
  {
    tabFor1: "vertical-tab-two",
    tabName: "password resets",
  },
  {
    tabFor1: "vertical-tab-three",
    tabName: "user provisioning",
  },
  {
    tabFor1: "vertical-tab-four",
    tabName: "access management",
  },
  {
    tabFor1: "vertical-tab-five",
    tabName: "asset requests",
  },
  {
    tabFor1: "vertical-tab-six",
    tabName: "employee onboarding",
  },
  {
    tabFor1: "vertical-tab-seven",
    tabName: "employee offboarding",
  },
];

const featureContent = [
  {
    header:
      "Add pre-built IT workflow automations to IT Chatbot from our Marketplace",
    content:
      " Workativ comes with over 400+ pre-built workflow automations for popular apps like ITSM, HR, Access Management, MDM, Knowledge Management and so on, that are ready to use from our workflow marketplace.  ",
    content1: "No coding. Just download, connect, and go live instantly.",
    link: null,
    image: section_four,
    bgColor: "bg_landing_yellow",
    iD: "marketplace",
    alt: "IT Support ai",
  },
  {
    header: "Deploy IT Support Chatbot on your Slack or Teams in few clicks",
    content:
      "Workplace support automation using Slack or Teams have shown to really improve MTTR and FCR. Customers have seen an average of 98% CSAT in employee service experience. ",
    content2:
      " Delight your employees with always-on 24/7, follow-me, auto-resolution IT chatbot, from the comfort of their chat apps like Slack or Teams.",
    link: null,
    image: section_five,
    toDisplaySlackIcons: true,
    bgColor: "bg_landing_white",
    iD: "slack",
    alt: "chatbot for it service desk",
  },
];

const cardData = [
  {
    header: "Encryption",
    content:
      "AES 256 bit encryption with 1,024-bit key-strength for data at Rest and TLS encryption for data in transit.",
    image: noCoding,
  },
  {
    header: "Access Controls",
    content:
      "Workativ implements role-based access through IAM that enforces segregation of duties, two-factor authentication, and end-to-end audit trails, ensuring access is in accordance with security policy.",
    image: supportAutomation,
  },
  {
    header: "Connect On-prem Apps",
    content: `
    Connect IT Support Chatbot with your on-prem apps using our <span><a href='/conversational-ai-platform/on-prem-app-connector'>secure connector</a></span> so you can create workflows to automate tasks from chatbot without a hitch, be it on-prem or cloud.`,
    image: boostIT,
  },
];

const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);

const headerMenu = [
  {
    menu: "IT Support Chatbot",
    href: "#chatbot",
    id: "chatbot",
    offset: null,
  },
  {
    menu: "IT Workflows",
    href: "#workflow",
    id: "workflow",
    offset: 450,
  },
  {
    menu: "Marketplace",
    href: "#marketplace",
    id: "marketplace",
    offset: 150,
  },
  {
    menu: "Slack & MS Teams",
    href: "#slack",
    id: "slack",
    offset: 150,
  },
  {
    menu: "App Integrations",
    href: "#integrations",
    id: "integrations",
    offset: 150,
  },
];

const OpenMenu = () => {
  const [isActive, setIsActive] = React.useState("HR Chatbot");

  const [showMenu, setShowMenu] = React.useState(false);
  const onClick = () => setShowMenu(!showMenu);
  return (
    <>
      <a
        className={
          !showMenu
            ? "navbar-brand js-scroll-trigger"
            : "navbar-brand js-scroll-trigger icon_open"
        }
        href="javascript:void(0);"
      >
        <img
          src={require("../../assets/images/workativ-logo.png")}
          alt="Workativ"
        />
      </a>
      <span>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span
            className={
              !showMenu
                ? "navbar-toggler-icon"
                : "navbar-toggler-icon icon_open"
            }
            onClick={onClick}
          >
            {!showMenu ? (
              <svg viewBox="0 -53 384 384">
                <path d="M368 154.668H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 32H16C7.168 32 0 24.832 0 16S7.168 0 16 0h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 277.332H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0"></path>
              </svg>
            ) : (
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 512.001 512.001"
                xmlSpace="preserve"
              >
                <path d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0014.143 5.857 19.94 19.94 0 0014.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z"></path>
              </svg>
            )}
          </span>
        </button>
      </span>
      {showMenu ? (
        <div id="results" className="search-results">
          <div className="navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              {headerMenu.map((menu) => (
                <li className="nav-item" onClick={onClick}>
                  <AnchorLink
                    offset={() => 160}
                    href={menu.href}
                    className={
                      isActive === menu.menu
                        ? "url_manipulation nav-link js-scroll-trigger active"
                        : "url_manipulation nav-link js-scroll-trigger "
                    }
                    onClick={() => {
                      setIsActive(menu.menu);
                    }}
                  >
                    {" "}
                    {menu.menu}
                  </AnchorLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
};

const Menu = () => (
  <div id="results" className="search-results">
    <div className="navbar-collapse" id="navbarResponsive">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#chatbot"
          >
            HR Chatbot
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#workflow"
          >
            HR Workflows
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#marketplace"
          >
            Marketplace
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#slack"
          >
            Slack & MS Teams
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#integrations"
          >
            App Integrations
          </AnchorLink>
        </li>
      </ul>
    </div>
  </div>
);

export default function FeatureHomePage() {
  const [isActive, setIsActive] = React.useState("HR Chatbot");

  const slides = [
    {
      image: unlock_account,
      title: "Unlock Account",
      tabFor: "vertical-tab-one",
    },
    {
      image: password_resets,
      title: "Password Reset",
      tabFor: "vertical-tab-two",
    },
    {
      image: user_provisioning,
      title: "User Provisioning",
      tabFor: "vertical-tab-three",
    },
    {
      image: access_management,
      title: "Access Management",
      tabFor: "vertical-tab-four",
    },
    {
      image: asset_requests,
      title: "Asset Requests",
      tabFor: "vertical-tab-five",
    },
    {
      image: onboarding,
      title: "Onboarding",
      tabFor: "vertical-tab-six",
    },
    {
      image: offboarding,
      title: "Offboarding",
      tabFor: "vertical-tab-seven",
    },
  ];

  const slideWidth = 100;
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // console.log("decrementOffset")
      incrementOffset();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const total = slides.length; // the length of the slide array
  const enabled = true; // pauses/disables the player
  const useLoaded = false; // this allows for delayed loads like images or embeds
  const speed = 10000; // speed in milliseconds to show each slide
  const loop = true;

  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState([]);
  function incrementOffset() {
    if (offset === total - 1) {
      setOffset(loop ? 0 : offset);
    } else {
      // console.log("setOffset", offset)
      setOffset(offset + 1);
    }
  }

  function decrementOffset() {
    if (offset === 0) {
      setOffset(loop ? total - 1 : offset);
    } else {
      setOffset(offset - 1);
    }
  }

  const loaded = useLoaded ? items.length === total : true;
  // useInterval(() => {
  //   if (loaded && enabled && offset < total) {
  //     incrementOffset();
  //   }
  // }, speed);

  const [isOpen, setOpen] = React.useState(false);

  const [header, setHeader] = useState("top_menu_sticky");

  const [dropdown, setDropdown] = useState("HR Chatbot");

  const listenScrollEvent = (e) => {
    if (window.scrollY < 73) {
      return setHeader("top_menu_sticky");
    } else if (window.scrollY > 70) {
      return setHeader("top_menu_sticky top_menu_sticky_fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Free IT Support Chatbot | IT Support Automation | IT Support AI"
        description="Use free IT service desk chatbot to automate IT issues and requests, Augment your IT support agents with AI chatbot automation for employee support"
        keywords={[
          "HR chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Support  chatbot free trial",
          "automation",
        ]}
        ogImage={topImage}
      />
      <section className="it_support_chatbot  container_trial integrations_container landing_page_chatbot">
        <Container>
          <Layout backgroundColor={"landing_bg"} logoFor="ASSISTANT">
            <RegisterSection
              rightImage={topImage}
              backgroundColor={"landing_bg"}
              altImage={"IT Support automation"}
              additionalClass={"padding-top-15"}
              iD={"chatbot"}
              bannerImage={customerBanner}
              bannerImageMobile={bannerImageMobile}
            >
              <RegisterSection.FormWithContent>
                <RegisterSection.MainHeader>
                  80% of repetitive IT support issues can be automated
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Workativ helps you automate your employee IT issues and
                  service requests with intelligent IT Support Chatbot, combined
                  with IT Workflow Automation.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>
            {isSmall ? (
              <OnboardingMobile
                header={onboardoingSliderData.header.mobile}
                subHeader={onboardoingSliderData.subHeader.mobile}
                sliders={onboardoingSliderData.sliders}
                additionalContainerClass="padding-top-30 float-left"
              />
            ) : (
              <OnboardingSlider
                header={onboardoingSliderData.header.desktop}
                subHeader={onboardoingSliderData.subHeader.desktop}
                sliders={onboardoingSliderData.sliders}
                additionalContainerClass="padding-top-50"
              />
            )}
            <Features
              header={featureComponents.header}
              mobileHeader={featureComponents.mobileHeader}
              dataToRender={featureComponents.sliders}
              autoplay={false}
            />

            <div class="container">
              <div className="build_needs">
                <div
                  class="col-lg-12 col-md-12 col-12 pl-0 center_features_header"
                  style="margin-bottom: 20px;"
                >
                  <h2 className="font-section-header">
                    Steps to build IT Support Chatbot with app automation
                  </h2>
                </div>
              </div>
            </div>
            <VideoElement2
              videoURL={"https://www.youtube-nocookie.com/embed/DvZpkC54JYg"}
              imgURL={VidImg}
              imgMob={VidImg}
            />
            {/* <section
              className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_transparent"
              id="chatbot"
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-6 feature_page_img_right ">
                    <img src={section_two} alt="it digital assistant" />
                  </div>
                  <div className="col-md-6 feature_page_content_left">
                    <h3 className="font-section-header">
                      Cover a wide range of topics for your IT Support Chatbot
                    </h3>
                    <p>
                      Using workativ’s platform, you can add, remove or optimize
                      new topics for chatbot easily based on your requirement.
                      Use our no-code canvas to create engaging employee
                      experiences.{" "}
                    </p>
                    <p className="second_p">
                      Go digital with IT Support Chatbot to deliver employee
                      support – faster, smarter, and easier.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <TabProvider defaultTab="vertical-tab-one" vertical>
              <section
                className="features_img_left pl-10-tage landing_page trial_page_img bg_landing_white"
                id="workflow"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 feature_page_content_left">
                      <h2 className="font-section-header">
                        IT Support Chatbot + IT Workflow Automation in a single
                        platform
                      </h2>
                      <p className="desktop_tab_list">
                        Workativ platform provides one-click integration of IT
                        chatbot with your apps to streamline and automate
                        repetitive IT issues & requests such as{" "}
                        {buttonMenu.slice(0, 5).map((menu, i) => (
                          <li className="button_list_li">
                            <span
                              className={isActive === menu.tabName ? "" : ""}
                              onClick={() => {
                                setIsActive(menu.tabName);
                                setOffset(i);
                              }}
                            >
                              {" "}
                              {menu.content && (
                                <span className="pad_content">
                                  {menu.content}
                                </span>
                              )}
                              {menu.tabFor1 ? (
                                <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                              ) : null}
                            </span>
                            <span className="space_left"> , </span>
                          </li>
                        ))}
                        and employee processes like{" "}
                        {buttonMenu.slice(5, 7).map((menu, i) => (
                          <li className="button_list_li">
                            <span
                              className={isActive === menu.tabName ? "" : ""}
                              onClick={() => {
                                setIsActive(menu.tabName);
                                setIsActive(i + 5);
                              }}
                            >
                              {" "}
                              {menu.content && (
                                <span className="pad_content">
                                  {menu.content}
                                </span>
                              )}
                              {menu.tabFor1 ? (
                                <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                              ) : null}
                            </span>
                            <span className="space_left last_child"> , </span>
                          </li>
                        ))}{" "}
                        and much more.
                      </p>
                      <p className="mobile_tab_list">
                        Workativ platform provides one-click integration of IT
                        chatbot with your apps to streamline and automate
                        repetitive IT issues & requests such as{" "}
                        {buttonMenu.slice(0, 5).map((menu, i) => (
                          <li className="button_list_li">
                            <AnchorLink
                              href={"#" + menu.tabFor1}
                              offset={() => -400}
                            >
                              <span
                                className={isActive === menu.tabName ? "" : ""}
                                onClick={() => {
                                  setIsActive(menu.tabName);
                                  setOffset(i);
                                }}
                              >
                                {" "}
                                {menu.content && (
                                  <span className="pad_content">
                                    {menu.content}
                                  </span>
                                )}
                                {menu.tabFor1 ? (
                                  <Tab tabFor={menu.tabFor1}>
                                    {menu.tabName}
                                  </Tab>
                                ) : null}
                              </span>
                              <span className="space_left"> , </span>
                            </AnchorLink>
                          </li>
                        ))}{" "}
                        and employee processes like{" "}
                        {buttonMenu.slice(5, 7).map((menu, i) => (
                          <li className="button_list_li">
                            <AnchorLink
                              href={"#" + menu.tabFor1}
                              offset={() => -400}
                            >
                              <span
                                className={isActive === menu.tabName ? "" : ""}
                                onClick={() => {
                                  setIsActive(menu.tabName);
                                  setOffset(i + 5);
                                }}
                              >
                                {" "}
                                {menu.content && (
                                  <span className="pad_content">
                                    {menu.content}
                                  </span>
                                )}
                                {menu.tabFor1 ? (
                                  <Tab tabFor={menu.tabFor1}>
                                    {menu.tabName}
                                  </Tab>
                                ) : null}
                              </span>
                              <span className="space_left last_child"> , </span>
                            </AnchorLink>
                          </li>
                        ))}{" "}
                        and much more.
                      </p>

                      <p className="second_p">
                        Expect a big drop in calls and emails to your IT
                        Support .
                      </p>
                      <div className="card_link_landing" />
                    </div>
                    <div className="col-md-6 feature_page_img_right mob_slider_pad_0">
                      <div className="desktop_tabs">
                        <TabList>
                          {slides.map((menu) => (
                            <Tab tabFor={menu.tabFor}>{menu.title}</Tab>
                          ))}
                        </TabList>
                        {slides.map((menu) => (
                          <TabPanel tabId={menu.tabFor}>
                            <img src={menu.image} alt={menu.title}></img>
                          </TabPanel>
                        ))}
                      </div>
                      <div className="slider_wrapper">
                        <div
                          className="slider_container"
                          id={slides[offset].tabFor}
                          {...handlers}
                        >
                          <img
                            src={slides[offset].image}
                            alt={slides[offset].title}
                          />
                        </div>
                        <div className="controls_wrapper">
                          <span
                            className="controls_left"
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              decrementOffset();
                            }}
                          >
                            {isSmall ? (
                              <MobNextArrowIcon />
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <g
                                  id="left_arrow"
                                  transform="translate(-20 -1622)"
                                >
                                  <circle
                                    id="Ellipse_254"
                                    data-name="Ellipse 254"
                                    cx="12"
                                    cy="12"
                                    r="12"
                                    transform="translate(20 1622)"
                                    fill="#fff"
                                  />
                                  <g
                                    id="Group_6414"
                                    data-name="Group 6414"
                                    transform="translate(-396 -258)"
                                  >
                                    <g
                                      id="Group_6413"
                                      data-name="Group 6413"
                                      transform="translate(424 1886)"
                                    >
                                      <path
                                        id="Path_3332"
                                        data-name="Path 3332"
                                        d="M430.629,1898.239a.508.508,0,0,1-.344-.134l-6.119-5.609a.51.51,0,0,1,0-.752l6.119-5.609a.51.51,0,0,1,.689.752l-5.709,5.234,5.709,5.233a.51.51,0,0,1-.345.886Z"
                                        transform="translate(-424 -1886)"
                                        fill="#2c7357"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            )}
                          </span>
                          <span>{slides[offset].title}</span>
                          <span
                            className="controls_right"
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              incrementOffset();
                            }}
                          >
                            {isSmall ? (
                              <MobNextArrowIcon />
                            ) : (
                              <svg
                                id="right_arrow"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  id="Ellipse_254"
                                  data-name="Ellipse 254"
                                  cx="12"
                                  cy="12"
                                  r="12"
                                  fill="#fff"
                                />
                                <g
                                  id="Group_6414"
                                  data-name="Group 6414"
                                  transform="translate(8.861 6)"
                                >
                                  <g id="Group_6413" data-name="Group 6413">
                                    <path
                                      id="Path_3332"
                                      data-name="Path 3332"
                                      d="M424.51,1898.239a.508.508,0,0,0,.344-.134l6.119-5.609a.51.51,0,0,0,0-.752l-6.119-5.609a.51.51,0,0,0-.689.752l5.709,5.234-5.709,5.233a.51.51,0,0,0,.345.886Z"
                                      transform="translate(-424 -1886)"
                                      fill="#2c7357"
                                    />
                                  </g>
                                </g>
                              </svg>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </TabProvider>

            {featureContent.map((feature, index) => {
              if (index % 2 !== 0) {
                return (
                  <RightImageWithContentFeature
                    image={feature.image}
                    bgColor={feature.bgColor}
                    iD={feature.iD}
                    altImage={feature.alt}
                  >
                    <RightImageWithContentFeature.Header>
                      <h3>{feature.header}</h3>
                    </RightImageWithContentFeature.Header>
                    <RightImageWithContentFeature.SubHeader>
                      <p
                        dangerouslySetInnerHTML={{ __html: feature.content }}
                      />
                      {feature.content1 && (
                        <p
                          dangerouslySetInnerHTML={{ __html: feature.content1 }}
                        />
                      )}
                      {feature.content2 && (
                        <p
                          dangerouslySetInnerHTML={{ __html: feature.content2 }}
                        />
                      )}
                    </RightImageWithContentFeature.SubHeader>
                    <RightImageWithContentFeature.Link>
                      {feature.link && (
                        <div className="card_link_landing">
                          <a href={feature.url} className="url_manipulation">
                            {feature.link}
                            <span className="arrow_svg_link">
                              <ArrowRightICon />
                            </span>
                          </a>
                        </div>
                      )}
                      {feature.toDisplaySlackIcons != undefined && (
                        <SlackAndTeams />
                      )}
                    </RightImageWithContentFeature.Link>
                  </RightImageWithContentFeature>
                );
              }
              return (
                <LeftImageWithContent
                  image={feature.image}
                  bgColor={feature.bgColor}
                  iD={feature.iD}
                  altImage={feature.alt}
                >
                  <LeftImageWithContent.MainHeader>
                    {feature.header}
                  </LeftImageWithContent.MainHeader>
                  <LeftImageWithContent.SubHeader>
                    <p dangerouslySetInnerHTML={{ __html: feature.content }} />
                    {feature.content1 && (
                      <p
                        className="second_p"
                        dangerouslySetInnerHTML={{ __html: feature.content1 }}
                      />
                    )}
                    {feature.content2 && (
                      <p
                        dangerouslySetInnerHTML={{ __html: feature.content2 }}
                      />
                    )}
                  </LeftImageWithContent.SubHeader>
                  <LeftImageWithContent.Link>
                    <div className="card_link_landing">
                      {feature.link && (
                        <a href={feature.link} className="url_manipulation">
                          Learn more{" "}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      )}
                      {feature.toDisplaySlackIcons != undefined && (
                        <SlackAndTeams />
                      )}
                    </div>
                  </LeftImageWithContent.Link>
                </LeftImageWithContent>
              );
            })} */}
            <AppsIntegrations
              header={
                <>
                  <h3 className="font-section-header mb-2">
                    Connect IT Support Chatbot with 70+ apps, 600+ actions, and
                    1000s of automations instantly. No coding.
                  </h3>
                </>
              }
              footer={
                <>
                  Also available CRM, ITSM, MDM Apps, Email Management, SMS
                  Management, Notifications, and Collaboration Apps.
                </>
              }
            />
            <section className="center_image_home cards_landing_page pb-0 mb-0">
              <div className="container">
                <div className="col-md-12">
                  <div className="row">
                    <div className="main-slider-left">
                      <h3 className="font-section-header">
                        Your data is yours and you control it
                      </h3>
                      <p className="font-section-normal-text">
                        Workativ has implemented robust security processes and
                        controls that are in compliance with industry-leading
                        standards and regulations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="automation_steps iso">
              <div className="container">
                {isSmall ? (
                  <picture>
                    <img className="center" src={img_mob} />
                  </picture>
                ) : (
                  <picture>
                    <img className="center" src={img} />
                  </picture>
                )}
              </div>
            </section>
            <div className="ahref-wrapper">
              <Link
                to="https://workativ.com/security"
                className="url_manipulation font-section-normal-text-medium"
              >
                Learn more about our security{" "}
              </Link>
            </div>
            <div className="container">
              <div className="usecase_blog_container">
                <h3 class="font-section-sub-header-small-bold-v2">
                  Must Read Blogs
                </h3>
                <div className="usecase_bottom_blog">
                  <ul>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/virtual-agent-powered-it-helpdesk"
                      >
                        How Virtual Agent can help IT Service Desks? Upgrade to
                        a Virtual Agent powered IT Helpdesk
                      </a>
                    </li>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide"
                      >
                        Slack Chatbot Guide for IT Helpdesk Automation
                      </a>
                    </li>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot"
                      >
                        What Is Conversational AI? A Guide to Conversational AI
                        Chatbots
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <RequestForm isFooterForm={true} />
            <GoToPopup />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const featureComponents = {
  header: (
    <h4 className="heading_feature font-section-header">
      IT Support Chatbot Capabilities
    </h4>
  ),
  mobileHeader: (
    <h4
      className="heading_mob font-section-sub-header"
      style="line-height: 1.5;"
    >
      IT Support Chatbot Capabilities
    </h4>
  ),
  sliders: [
    {
      title: "IT Support Chatbot",
      desktopComponent: (
        <>
          <div className="right_header_content padding-top-15 mr-8">
            <h5 className="font-section-sub-header">
              Cover a wide range of topics for your IT Support Chatbot
            </h5>
            <p className="font-section-normal-text">
              Using our no-code bot platform, you can easily add, remove, or
              optimze support topics for chatbot based on your requirement in
              minutes.{" "}
            </p>

            <p className="font-section-normal-text">
              {" "}
              Go digital with IT Support Chatbot to deliver employee support -
              faster, smarter, and easier.
            </p>
          </div>
          <img className="" src={AllFeatureImg1} alt="IT Support Chatbot"></img>
        </>
      ),

      mobileComponent: (
        <>
          {" "}
          <h5 className="font-section-sub-header">
            Cover a wide range of topics for your IT Support Chatbot
          </h5>
          <p className="font-section-normal-text">
            Using our no-code bot platform, you can easily add, remove, or
            optimze support topics for chatbot based on your requirement in
            minutes.{" "}
          </p>
          <p className="font-section-normal-text mb-0">
            {" "}
            Go digital with IT Support Chatbot to deliver employee support -
            faster, smarter, and easier.
          </p>
          <img
            className="mob_feature_img"
            src={AllFeatureImg1}
            alt="IT Support Chatbot"
          ></img>
        </>
      ),
    },
    {
      title: "App Workflow Automation",
      type: "slider",
      desktopComponent: () => (
        <>
          <div class="right_header_content mr-8">
            <SectionWithImageTabs slides={slides} />
          </div>
        </>
      ),
      mobileComponent: () => (
        <>
          <div class="right_header_content mr-8 mobile-section-with-tabs">
            <SectionWithImageTabs slides={slides} />
          </div>
        </>
      ),
    },

    {
      title: "Bot Marketplace",
      desktopComponent: (
        <>
          <div className="right_header_content padding-top-15 mr-8">
            <h5 className="font-section-sub-header">
              Add pre-built IT workflow automations to IT Support Chatbot from
              our Marketplace
            </h5>
            <p className="font-section-normal-text">
              Workativ comes with over 600+ pre-built app workflow automations
              for popular apps like ITSM, HR, Access Management, MDM, Knowledge
              Management and so on, that are ready to use from our workflow
              marketplace.  No coding.
            </p>
            <p className="font-section-normal-text">
              Just download, connect, and go live instantly.
            </p>
          </div>
          <img
            className=""
            src={AllFeatureImg3}
            alt="IT Support Chatbot Workflows Marketplace"
          ></img>
        </>
      ),

      mobileComponent: (
        <>
          {" "}
          <h5 className="font-section-sub-header">
            Add pre-built IT workflow automations to IT Support Chatbot from our
            Marketplace
          </h5>
          <p className="font-section-normal-text">
            Workativ comes with over 600+ pre-built app workflow automations for
            popular apps like ITSM, HR, Access Management, MDM, Knowledge
            Management and so on, that are ready to use from our workflow
            marketplace.  No coding.
          </p>
          <p className="font-section-normal-text  mb-0">
            Just download, connect, and go live instantly.
          </p>
          <img
            className="mob_feature_img"
            src={AllFeatureImg3}
            alt="IT Support Chatbot Workflows Marketplace"
          ></img>
        </>
      ),
    },

    {
      title: "Omnichannel",
      desktopComponent: (
        <>
          <div className="right_header_content padding-top-15 mr-8">
            <h5 className="font-section-sub-header">
              Deploy IT Support Chatbot on your Slack, Teams or as a Widget in
              few clicks
            </h5>
            <p className="font-section-normal-text">
              Companies using IT Bot have seen to automate 60% of issues &
              requests, and scale support much faster.
            </p>

            <p className="font-section-normal-text">
              {" "}
              Delight your employees with always-on 24/7, follow-me,
              auto-resolution IT Support chatbot, from the comfort Slack, Teams,
              or a Chat Widget.
            </p>
          </div>
          <img
            className=""
            src={AllFeatureImg4}
            alt="IT Support Chatbot Ominichannel"
          ></img>
        </>
      ),

      mobileComponent: (
        <>
          {" "}
          <h5 className="font-section-sub-header">
            Deploy IT Support Chatbot on your Slack, Teams or as a Widget in few
            clicks
          </h5>
          <p className="font-section-normal-text">
            Companies using IT Bot have seen to automate 60% of issues &
            requests, and scale support much faster.
          </p>
          <p className="font-section-normal-text  mb-0">
            {" "}
            Delight your employees with always-on 24/7, follow-me,
            auto-resolution IT Support chatbot, from the comfort Slack, Teams,
            or a Chat Widget.
          </p>
          <img
            className="mob_feature_img"
            src={AllFeatureImg4}
            alt="IT Support Chatbot Ominichannel"
          ></img>
        </>
      ),
    },

    {
      title: "Agent Handover",
      desktopComponent: (
        <>
          <div className="right_header_content padding-top-15 mr-8">
            <h5 className="font-section-sub-header">Live Agent Handover</h5>
            <p className="font-section-normal-text">
              Workativ offers seamless{" "}
              <span className="font-section-normal-text-medium color_black_span">
                {" "}
                'agent handover’{" "}
              </span>
              of chatbot to live agents with complete context and conversation
              history so your agents can pick up right where the bot left,
              avoiding any user frustration – but most importantly solving
              end-user issues right away.
            </p>
          </div>
          <img
            className=""
            src={AllFeatureImg5}
            alt="IT Support Chatbot Agent Handover"
          ></img>
        </>
      ),

      mobileComponent: (
        <>
          {" "}
          <h5 className="font-section-sub-header">Live Agent Handover</h5>
          <p className="font-section-normal-text  mb-0">
            Workativ offers seamless{" "}
            <span className="color_span_black">‘agent handover’</span> of
            chatbot to live agents with complete context and conversation
            history so your agents can pick up right where the bot left,
            avoiding any user frustration – but most importantly solving
            end-user issues right away.
          </p>
          <img
            className="mob_feature_img"
            src={AllFeatureImg5}
            alt="IT Support Chatbot Agent Handover"
          ></img>
        </>
      ),
    },
  ],
};

const onboardoingSliderData = {
  header: {
    desktop: (
      <h2 className="font-section-header">
        Go live in minutes with our onscreen onboarding
      </h2>
    ),
    mobile: (
      <h3 className="font-section-header">
        Go live in minutes with our onscreen onboarding
      </h3>
    ),
  },
  subHeader: {
    desktop: (
      <h5 className="font-section-normal-text-medium sign_up_font_size_reduce">
        Sign up for FREE Bot
        <a
          style="color: #1857DC;"
          href="https://assistant.workativ.com/authentication/u/direct-signup"
        >
          {" "}
          here
        </a>
      </h5>
    ),
    mobile: (
      <h5 className="font-section-normal-text-medium sign_up_font_size_reduce">
        Sign up for FREE Bot{" "}
        <a
          style="color: #1857DC;"
          href="https://assistant.workativ.com/authentication/u/direct-signup"
        >
          {" "}
          here
        </a>
      </h5>
    ),
  },
  sliders: [
    {
      title: "Sign up",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Sign up
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                You can create a new bot or download pre-built IT Support
                Chatbot from marketplace.
              </p>
            </div>
          </div>

          <div className="right_section">
            <img src={OnboardSlide} alt="sign_up"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">Sign up</h3>
            <p className="font-section-normal-text">
              You can create a new bot or download pre-built IT Support Chatbot
              from marketplace.
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide} alt="sign_up"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Download IT Bot",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Download IT Support Chatbot
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Bot templates are pre-built bots with conversations covering
                most common and repetitive support topics. Easily download IT
                Support Chatbot in one-click, add new or edit existing support
                topics in minutes.
              </p>
            </div>
          </div>

          <div className="right_section">
            <img src={OnboardSlide1} alt="bot_marketplace"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="onboarding_slider_content_header font-section-sub-header">
              Download IT Support Chatbot
            </h3>
            <p className="responsive-p-slider font-section-normal-text">
              Bot templates are pre-built bots with conversations covering most
              common and repetitive support topics. Easily download IT Support
              Chatbot in one-click, add new or edit existing support topics in
              minutes.
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide1} alt="bot_marketplace"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Connect App Workflows",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Connect App Workflows
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Connecting chatbot with your apps and building app workflows
                requires a lot of development efforts, time, and costs. But
                workativ provides pre-built app integrations and app workflows
                so you can connect your chatbot with your app and automate tasks
                instantly. No coding.
              </p>
            </div>
          </div>

          <div className="right_section">
            <img src={OnboardSlide2} alt="connect_app_work"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="onboarding_slider_content_header font-section-sub-header">
              Connect App Workflows
            </h3>
            <p className="responsive-p-slider font-section-normal-text">
              Connecting chatbot with your apps and building app workflows
              requires a lot of development efforts, time, and costs. But
              workativ provides pre-built app integrations and app workflows so
              you can connect your chatbot with your app and automate tasks
              instantly. No coding.
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide2} alt="connect_app_work"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Test your Bot",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Bot Testing
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Test your bot in real time using “Try Me”. Make adjustments, add
                or edit conversations, fine tune user interactions, and review
                your bot conversations on the go before you go-live.
              </p>
            </div>
          </div>
          <div className="right_section">
            <img src={OnboardSlide3} alt="bot_testing"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">Bot Testing</h3>
            <p className="font-section-normal-text">
              Test your bot in real time using “Try Me”. Make adjustments, add
              or edit conversations, fine tune user interactions, and review
              your bot conversations on the go before you go-live.
            </p>
          </div>

          <div className="image_section">
            <img src={OnboardSlide3} alt="bot_testing"></img>
          </div>
        </div>
      ),
    },
    {
      title: "Go Live",
      desktopComponent: (
        <div className="onboarding_section_container">
          <div className="left_section">
            <div className="onboaring_arrow">
              <img src={OnboardArrow}></img>
            </div>
            <div className="onboaring_arrow_bottom_content">
              <h3 className="onboarding_slider_content_header font-section-sub-header">
                Deploy on Teams, Slack, or as Chat Widget
              </h3>
              <p className="responsive-p-slider font-section-normal-text">
                Turn your favorite collaboration app into modern employee
                self-service by deploying chatbot on your Teams, Slack or as
                Widget on your self-help portal in few clicks. Customize the
                end-user experience by applying your company branding, logo etc.
              </p>
              <div className="icons_container_onboarding">
                <div className="icon_text">
                  <img
                    class="slack_feature_icon_onboarding"
                    src={FeatureSlack}
                  ></img>
                  <h6 className="font-section-small-text-medium  mb-0 pt-1">
                    Slack
                  </h6>
                </div>

                <div className="icon_text">
                  <img
                    class="teams_feature_icon_onboarding"
                    src={FeatureTeams}
                  ></img>
                  <h6 className="font-section-small-text-medium mb-0 pt-1">
                    Teams
                  </h6>
                </div>
                <div className="icon_text">
                  <img
                    class="chat_feature_icon_onboarding"
                    src={FeatureChat}
                  ></img>
                  <h6 className="font-section-small-text-medium  mb-0 pt-1">
                    Chat Widget
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="right_section">
            <img src={OnboardSlide4} alt="deploy_teams_slack_chat"></img>
          </div>
        </div>
      ),
      mobileComponent: (
        <div className="onboarding_mobile_section_container">
          <div className="mobile_section">
            <h3 className="font-section-sub-header">
              Deploy on Teams, Slack, or as Chat Widget
            </h3>
            <p className="font-section-normal-text">
              Turn your favorite collaboration app into modern employee
              self-service by deploying chatbot on your Teams, Slack or as
              Widget on your self-help portal in few clicks. Customize the
              end-user experience by applying your company branding, logo etc.
            </p>
            <div className="icons_container_onboarding_mobile">
              <img
                class="slack_feature_icon_onboarding_mobile"
                src={FeatureSlack}
              ></img>
              <h6>Slack</h6>

              <img
                class="teams_feature_icon_onboarding_mobile"
                src={FeatureTeams}
              ></img>
              <h6>Teams</h6>

              <img
                class="chat_feature_icon_onboarding_mobile"
                src={FeatureChat}
              ></img>
              <h6>Chat Widget</h6>
            </div>
          </div>

          <div className="image_section">
            <img src={OnboardSlide4} alt="deploy_teams_slack_chat"></img>
          </div>
        </div>
      ),
    },
  ],
};
export const SectionWithImageTabs = (props) => {
  // console.log("SectionWithImageTabs ::: 1", slides);
  const { slides } = props;
  const [isActive, setIsActive] = React.useState("Unlock Account");

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      incrementOffset();
    },
    onSwipedRight: () => {
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const total = slides.length; // the length of the slide array
  const enabled = true; // pauses/disables the player
  const useLoaded = false; // this allows for delayed loads like images or embeds
  // const speed = 10000; // speed in milliseconds to show each slide
  const loop = true;

  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState([]);

  function incrementOffset() {
    if (offset === total - 1) {
      setOffset(loop ? 0 : offset);
    } else {
      setOffset(offset + 1);
    }
  }

  function decrementOffset() {
    if (offset === 0) {
      setOffset(loop ? total - 1 : offset);
    } else {
      setOffset(offset - 1);
    }
  }

  const loaded = useLoaded ? items.length === total : true;
  // useInterval(() => {
  //   if (loaded && enabled && offset < total) {
  //     incrementOffset();
  //   }
  // }, speed);

  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <TabProvider defaultTab="vertical-tab-one" vertical>
      <div>
        <h5 className="font-section-sub-header padding-top-15 margin-bottom-15">
          {" "}
          IT Support Chatbot + IT Workflow Automation in a single platform
        </h5>
        <p className="desktop_tab_list font-section-normal-text">
          Workativ platform provides one-click integration of IT chatbot with
          your apps to streamline and automate repetitive IT issues & requests
          such as{" "}
          {buttonMenu.slice(0, 5).map((menu, i) => (
            <li className="button_list_li image_slider_li font-section-normal-text-medium">
              <span
                className={isActive === menu.tabName ? "" : ""}
                onClick={() => {
                  setIsActive(menu.tabName);
                  setOffset(i);
                }}
              >
                {" "}
                {menu.content && (
                  <span className="pad_content">{menu.content}</span>
                )}
                {menu.tabFor1 ? (
                  <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                ) : null}
              </span>
              <span className="space_left"> , </span>
            </li>
          ))}{" "}
          and employee processes like{" "}
          {buttonMenu.slice(5, 7).map((menu, i) => (
            <li className="button_list_li  image_slider_li  font-section-normal-text-medium">
              <span
                className={isActive === menu.tabName ? "" : ""}
                onClick={() => {
                  setIsActive(menu.tabName);
                  setOffset(i + 5);
                }}
              >
                {" "}
                {menu.content && (
                  <span className="pad_content">{menu.content}</span>
                )}
                {menu.tabFor1 ? (
                  <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                ) : null}
              </span>
              <span className="space_left last_child"> , </span>
            </li>
          ))}{" "}
          and much more.
        </p>
        <p className="mobile_tab_list">
          Workativ platform provides one-click integration of IT Support chatbot
          with your apps to streamline and automate repetitive IT issues &
          requests such as{" "}
          <>
            {" "}
            {buttonMenu.map((menu, i) => (
              <li className="button_list_li  image_slider_li font-section-normal-text-medium">
                <AnchorLink href={"#" + menu.tabFor1} offset={() => -330}>
                  <span
                    className={isActive === menu.tabName ? "" : ""}
                    onClick={() => {
                      setIsActive(menu.tabName);
                      setOffset(i);
                    }}
                  >
                    {" "}
                    {menu.content && (
                      <span className="pad_content">{menu.content}</span>
                    )}
                    {menu.tabFor1 ? (
                      <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                    ) : null}
                  </span>
                  <span className="space_left"> , </span>
                </AnchorLink>
              </li>
            ))}
          </>
          exit feedbacks and more.
        </p>
        <p className="second_p text-black">
          Expect a big drop in calls and emails to your IT Support.
        </p>
      </div>
      <div className="feature_page_img_right mob_slider_pad_0 font-section-normal-text-medium">
        <div className="desktop_tabs justify-content-center  desktop_tab_width_change padding-bottom-40">
          <TabList>
            {slides.map((menu) => (
              <Tab tabFor={menu.tabFor}>{menu.title}</Tab>
            ))}
          </TabList>
          {slides.map((menu) => (
            <TabPanel tabId={menu.tabFor}>
              <img src={menu.image} alt={menu.title}></img>
            </TabPanel>
          ))}
        </div>
        <div className="slider_wrapper">
          <div
            className="slider_container"
            id={slides[offset].tabFor}
            {...handlers}
          >
            <img src={slides[offset].image} alt={slides[offset].title} />
          </div>
          <div className="controls_wrapper">
            <span
              className="controls_left"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                decrementOffset();
              }}
            >
              {isSmall ? (
                <MobNextArrowIcon />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g id="left_arrow" transform="translate(-20 -1622)">
                    <circle
                      id="Ellipse_254"
                      data-name="Ellipse 254"
                      cx="12"
                      cy="12"
                      r="12"
                      transform="translate(20 1622)"
                      fill="#fff"
                    />
                    <g
                      id="Group_6414"
                      data-name="Group 6414"
                      transform="translate(-396 -258)"
                    >
                      <g
                        id="Group_6413"
                        data-name="Group 6413"
                        transform="translate(424 1886)"
                      >
                        <path
                          id="Path_3332"
                          data-name="Path 3332"
                          d="M430.629,1898.239a.508.508,0,0,1-.344-.134l-6.119-5.609a.51.51,0,0,1,0-.752l6.119-5.609a.51.51,0,0,1,.689.752l-5.709,5.234,5.709,5.233a.51.51,0,0,1-.345.886Z"
                          transform="translate(-424 -1886)"
                          fill="#2c7357"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              )}
            </span>
            <span className="font-section-normal-text-medium  padding-top-10">
              {slides[offset].title}
            </span>
            <span
              className="controls_right"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                incrementOffset();
              }}
            >
              {isSmall ? (
                <MobNextArrowIcon />
              ) : (
                <svg
                  id="right_arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <circle
                    id="Ellipse_254"
                    data-name="Ellipse 254"
                    cx="12"
                    cy="12"
                    r="12"
                    fill="#fff"
                  />
                  <g
                    id="Group_6414"
                    data-name="Group 6414"
                    transform="translate(8.861 6)"
                  >
                    <g id="Group_6413" data-name="Group 6413">
                      <path
                        id="Path_3332"
                        data-name="Path 3332"
                        d="M424.51,1898.239a.508.508,0,0,0,.344-.134l6.119-5.609a.51.51,0,0,0,0-.752l-6.119-5.609a.51.51,0,0,0-.689.752l5.709,5.234-5.709,5.233a.51.51,0,0,0,.345.886Z"
                        transform="translate(-424 -1886)"
                        fill="#2c7357"
                      />
                    </g>
                  </g>
                </svg>
              )}
            </span>
          </div>
        </div>
      </div>
    </TabProvider>
  );
};

const integrationsApps = [
  {
    header: "13+ HR Apps",
    userApps: [
      {
        icon: bamboo,
        appName: "BambooHR",
        url: "/conversational-ai-platform/bamboohr-chatbot",
      },
      {
        icon: orangehrm,
        appName: "OrangeHRM",
        url: "/conversational-ai-platform/orange-hrm-chatbot",
      },
      {
        icon: orangehrm,
        appName: "Zoho People",
        url: "/conversational-ai-platform/zoho-people-chatbot",
      },
      {
        icon: gusto,
        appName: "Gusto",
        url: "/conversational-ai-platform/gusto-chatbot",
      },
      {
        icon: sap,
        appName: "SAP SuccessFactors",
        url: "/conversational-ai-platform/sap-successfactors-chatbot",
      },
    ],
  },
  {
    header: "5+ Document Management Apps",
    userApps: [
      {
        icon: okta,
        appName: "Okta",
        url: "/conversational-ai-platform/okta-chatbot",
      },
      {
        icon: ad,
        appName: "Microsoft Azure AD",
        url: "/conversational-ai-platform/active-directory-chatbot",
      },
      {
        icon: jumpcloud,
        appName: "Jumpcloud",
        url: "/conversational-ai-platform/jumpcloud-chatbot",
      },
      {
        icon: office365,
        appName: "Microsoft Office 365",
        url: "/conversational-ai-platform/office-365-chatbot",
      },
      {
        icon: onelogin,
        appName: "Onelogin",
        url: "/conversational-ai-platform/onelogin-chatbot",
      },
    ],
  },
  {
    header: "15+ Access Management Apps",
    userApps: [
      {
        icon: okta,
        appName: "Okta",
        url: "/conversational-ai-platform/okta-chatbot",
      },
      {
        icon: ad,
        appName: "Microsoft Azure AD",
        url: "/conversational-ai-platform/active-directory-chatbot",
      },
      {
        icon: jumpcloud,
        appName: "Jumpcloud",
        url: "/conversational-ai-platform/jumpcloud-chatbot",
      },
      {
        icon: office365,
        appName: "Microsoft Office 365",
        url: "/conversational-ai-platform/office-365-chatbot",
      },
      {
        icon: onelogin,
        appName: "Onelogin",
        url: "/conversational-ai-platform/onelogin-chatbot",
      },
    ],
  },
  {
    header: "05+ Expense Management Apps",
    userApps: [
      {
        icon: intune,
        appName: "Microsoft Intune",
        url: "/conversational-ai-platform/microsoft-intune-chatbot",
      },
      {
        icon: mobileiron,
        appName: "MobileIron",
        url: "/conversational-ai-platform/mobileiron-chatbot",
      },
      {
        icon: hexnode,
        appName: "Hexnode",
        url: "/conversational-ai-platform/hexnode-chatbot",
      },
      {
        icon: citrix,
        appName: "Citrix",
        url: "/conversational-ai-platform/citrix-chatbot",
      },
      {
        icon: service_desk,
        appName: "ManageEngine MDM",
        url: "/conversational-ai-platform/manageengine-mdm-chatbot",
      },
    ],
  },
];
